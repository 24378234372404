import _ from "lodash";
import { forkJoin, Observable, from } from "rxjs";
import { map, last, mergeScan } from "rxjs/operators";

export function forkJoinLimit<T>(limit: number, sources: [Observable<T>]): Observable<T[]>;
export function forkJoinLimit<T, T2>(
    limit: number,
    sources: [Observable<T>, Observable<T2>]
): Observable<[T, T2]>;
export function forkJoinLimit<T, T2, T3>(
    limit: number,
    sources: [Observable<T>, Observable<T2>, Observable<T3>]
): Observable<[T, T2, T3]>;
export function forkJoinLimit<T, T2, T3, T4>(
    limit: number,
    sources: [Observable<T>, Observable<T2>, Observable<T3>, Observable<T4>]
): Observable<[T, T2, T3, T4]>;
export function forkJoinLimit<T, T2, T3, T4, T5>(
    limit: number,
    sources: [Observable<T>, Observable<T2>, Observable<T3>, Observable<T4>, Observable<T5>]
): Observable<[T, T2, T3, T4, T5]>;
export function forkJoinLimit<T, T2, T3, T4, T5, T6>(
    limit: number,
    sources: [
        Observable<T>,
        Observable<T2>,
        Observable<T3>,
        Observable<T4>,
        Observable<T5>,
        Observable<T6>
    ]
): Observable<[T, T2, T3, T4, T5, T6]>;
// eslint-disable-next-line @typescript-eslint/unified-signatures
export function forkJoinLimit<T>(limit: number, sources: Array<Observable<T>>): Observable<T[]>;
// eslint-disable-next-line @typescript-eslint/unified-signatures
export function forkJoinLimit<T>(limit: number, v1: Observable<T>): Observable<T[]>;
export function forkJoinLimit<T, T2>(
    limit: number,
    v1: Observable<T>,
    v2: Observable<T2>
): Observable<[T, T2]>;
export function forkJoinLimit<T, T2, T3>(
    limit: number,
    v1: Observable<T>,
    v2: Observable<T2>,
    v3: Observable<T3>
): Observable<[T, T2, T3]>;
export function forkJoinLimit<T, T2, T3, T4>(
    limit: number,
    v1: Observable<T>,
    v2: Observable<T2>,
    v3: Observable<T3>,
    v4: Observable<T4>
): Observable<[T, T2, T3, T4]>;
export function forkJoinLimit<T, T2, T3, T4, T5>(
    limit: number,
    v1: Observable<T>,
    v2: Observable<T2>,
    v3: Observable<T3>,
    v4: Observable<T4>,
    v5: Observable<T5>
): Observable<[T, T2, T3, T4, T5]>;
export function forkJoinLimit<T, T2, T3, T4, T5, T6>(
    limit: number,
    v1: Observable<T>,
    v2: Observable<T2>,
    v3: Observable<T3>,
    v4: Observable<T4>,
    v5: Observable<T5>,
    v6: Observable<T6>
): Observable<[T, T2, T3, T4, T5, T6]>;
export function forkJoinLimit<T>(limit: number, ...sources: Array<Observable<T>>): Observable<T[]>;

export function forkJoinLimit<T>(
    limit: number,
    ...sources: Array<Observable<T> | Array<Observable<T>>>
): Observable<T[]> {
    let converted: Array<Observable<T>>;

    if (sources.length === 1 && _.isArray(sources[0])) {
        converted = sources[0] as Array<Observable<T>>;
    } else {
        converted = sources as Array<Observable<T>>;
    }

    if (converted.length < limit) {
        return forkJoin(converted);
    }

    return from(converted).pipe(
        mergeScan(
            (acc: T[], value, index) =>
                map((result: T) => {
                    acc[index] = result;
                    return acc;
                })(value),
            [],
            limit
        ),
        last()
    );
}
