import _ from "lodash";

export function toInteger(value: any, fallback?: number): number;
export function toInteger(
    value: any,
    fallback: number,
    min: number | null,
    max: number | null
): number;
export function toInteger(
    value: any,
    fallback?: number,
    min?: number | null,
    max?: number | null
): number {
    if (value == null) {
        value = fallback || 0;
    } else {
        if (!_.isNumber(value)) value = Number(value);
        if (isNaN(value)) value = fallback || 0;
    }
    value = Math.round(value);
    if (min != null && min > value) value = min;
    if (max != null && max < value) value = max;

    return value;
}
