/* eslint-disable prefer-const */
import { padEnd } from "lodash";

import { toFixedFix } from "../math/toFixedFix";

import { getNumberSeparators } from "./getNumberSeparators";

const numberFormatCache = new Map<string, Intl.NumberFormat>();
export interface FormatNumberOptions {
    val: number | string;
    locale: string;
    renderThousandSeparators?: boolean;
    decimals?: number;
    zeroDash?: boolean;
    forcePlus?: boolean;
    min?: number;
    max?: number;
}

export function formatNumber(options: FormatNumberOptions): string {
    let { val, locale, renderThousandSeparators, decimals, zeroDash, forcePlus, min, max } =
        options;

    renderThousandSeparators = renderThousandSeparators == null ? true : renderThousandSeparators;

    const value = (val + "").replace(/[^0-9+\-Ee.]/g, "");

    const n: number = !isFinite(+value) ? 0 : +value;
    const prec: number = decimals ? (!isFinite(+decimals) ? 0 : Math.abs(decimals)) : 0;

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    let rounded = prec ? toFixedFix(n, prec) : Math.round(n);
    let minMaxPrefix = "";

    if (min !== undefined && rounded < min) {
        rounded = min;
        minMaxPrefix = "< ";
    } else if (max !== undefined && rounded > max) {
        rounded = max;
        minMaxPrefix = "> ";
    }

    if (rounded === 0 && zeroDash && minMaxPrefix === "") return "−";

    const { thousand, decimal } = getNumberSeparators(locale);

    const key = `${locale}__${decimals}`;
    if (!numberFormatCache.has(key))
        numberFormatCache.set(
            key,
            new Intl.NumberFormat(locale, { maximumFractionDigits: decimals })
        );

    let formatted = numberFormatCache.get(key)!.format(rounded);

    if (!renderThousandSeparators) {
        formatted = formatted?.replace(thousand.removeRegex, "");
    }

    let [left, right] = formatted.split(decimal.symbol);

    let result = left;

    if (prec) {
        if (!right || right.length < prec) {
            right = padEnd(right || "", prec, "0");
        }

        result += decimal.symbol + right;
    }

    return minMaxPrefix + (+rounded > 0 && forcePlus ? "+" + result : result);
}
