import { Dictionary } from "lodash";
import { SimpleComparer } from "./lg-sort-by";

const cache: Dictionary<SimpleComparer<any>> = {};
type Sensitivity = "base" | "accent" | "case" | "variant";

interface GetLocaleComparerArgs {
    locales?: string | string[] | null;
    sensitivity?: Sensitivity;
    numeric?: boolean;
}

export function getLocaleComparer({
    locales = null,
    sensitivity = "accent",
    numeric = false
}: GetLocaleComparerArgs = {}): SimpleComparer<any> {
    if (locales === null) {
        locales = [];
    } else if (!Array.isArray(locales)) {
        locales = [locales];
    }

    const key = locales.join(",") + "__" + sensitivity + "__" + !!numeric;
    let comparer = cache[key];
    if (comparer) return comparer;

    const collater = new Intl.Collator(locales, { sensitivity, numeric });

    // Based on lodash sources; removed symbol support and fixed comparison bug. Also modified behaviour for NaN
    comparer = function localeComparer(value, other) {
        if (value !== other) {
            const valueIsDefined = value !== undefined;
            const valueIsNull = value === null;
            // eslint-disable-next-line no-self-compare
            const valueIsReflexive = value === value;
            // const valIsSymbol = isSymbol( value )  RG: optimization, we don't need these currently
            const valueIsSymbol = false;

            const otherIsDefined = other !== undefined;
            const otherIsNull = other === null;
            // eslint-disable-next-line no-self-compare
            const otherIsReflexive = other === other;
            // const othIsSymbol = isSymbol( other )
            const otherIsSymbol = false;

            const val =
                typeof value === "string" || typeof other === "string"
                    ? collater.compare(value, other)
                    : value - other;

            if (
                (!otherIsNull && !otherIsSymbol && !valueIsSymbol && val > 0) ||
                (valueIsSymbol &&
                    otherIsDefined &&
                    otherIsReflexive &&
                    !otherIsNull &&
                    !otherIsSymbol) ||
                (valueIsNull && otherIsDefined && otherIsReflexive) ||
                (!valueIsDefined && otherIsReflexive) ||
                (!valueIsReflexive && otherIsReflexive)
            ) {
                return 1;
            }
            if (
                (!valueIsNull && !valueIsSymbol && !otherIsSymbol && val < 0) ||
                (otherIsSymbol &&
                    valueIsDefined &&
                    valueIsReflexive &&
                    !valueIsNull &&
                    !valueIsSymbol) ||
                (otherIsNull && valueIsDefined && valueIsReflexive) ||
                (!otherIsDefined && valueIsReflexive) ||
                (!otherIsReflexive && valueIsReflexive)
            ) {
                return -1;
            }
        }

        return 0;
    };

    cache[key] = comparer;
    return comparer;
}
