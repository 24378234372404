import _ from "lodash";

/**
 * Return true if given object is promise.
 *
 * @param obj
 */
export function isPromise(obj: unknown): obj is Promise<any> {
    return obj != null && typeof obj === "object" && "then" in obj && _.isFunction(obj.then);
}
