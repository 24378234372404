import _ from "lodash";
import { IColumnFilterList, ICleanedColumnFilter } from "@logex/framework/types";

const keyCleanRegExpo = /^([^#]+)#.*$/g;

/**
 * Prepare a filter specification to be used by filterByColumnPrepared. This is useful when one wants to filter multiple
 * sets of data at a given time (like different nodes at given pivot level), but the prepared set shouldn't be kept around
 * if the filtering criteria have changed.
 *
 * This preparating includes the probe call to callback-based filters (see IColumnFilterFunction for details).
 *
 * The function is used internally by logexPivot
 *
 * @param filters is specifications of the filters to preprocess
 * @param ignoredFilter is name of the one filter that should be ignored
 * @return preprocessed list of the filters
 */
export function filterByColumnPrepare(
    filters?: IColumnFilterList | null,
    ignoredFilter?: string | string[]
): ICleanedColumnFilter[] | null {
    if (filters == null) return null;

    const prepared: ICleanedColumnFilter[] = [];
    const ignoredLookup: Record<string, boolean> = {};
    if (ignoredFilter) {
        if (_.isArray(ignoredFilter)) {
            for (const ignored of ignoredFilter) {
                ignoredLookup[ignored] = true;
            }
        } else {
            ignoredLookup[ignoredFilter] = true;
        }
    }
    for (const key in filters) {
        if (ignoredLookup[key]) continue;
        const filter = filters[key];
        if (!filter || (filter as any).$empty) continue;
        let isFunction = false;
        if (typeof filter === "function") {
            isFunction = true;
            try {
                /**
                 * When called without parameters, the callbacks can return "$empty" to indicate they're not active.
                 * Since this isn't always supported, we need to wrap the call in try/catch
                 */
                if ((filter as Function)() === "$empty") continue;
            } catch (e) {
                // empty
            }
        }
        const keyCleaned = key.replace(keyCleanRegExpo, "$1");
        prepared.push({ keyCleaned, filter, isFunction } as ICleanedColumnFilter);
    }
    return prepared;
}
